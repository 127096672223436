var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"modal__block modal__block--middle modal__block--success",attrs:{"name":_vm.name,"width":550,"min-width":550,"height":"auto"}},[_c('div',{staticClass:"modal__block--wrapper"},[_c('div',{staticClass:"modal__block--close",on:{"click":_vm.close}},[_c('svg',{attrs:{"width":"30","height":"30","viewBox":"0 0 30 30","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M22.5 7.5l-15 15M7.5 7.5l15 15","stroke":"#fff","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"modal__wrapper"},[_c('div',{staticClass:"modal__header"},[_c('div',{staticClass:"modal__title",staticStyle:{"min-height":"20px"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"modal__form"},[(_vm.message.text)?_c('div',{staticClass:"form__block--line form__messages",class:{
							'form__messages--error': _vm.message.status != 200,
							'form__messages--success': _vm.message.status == 200,
						}},[_c('label',{staticClass:"form__block--input"},[_c('span',{staticClass:"form__block--title"},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])])]):_vm._e(),(_vm.errorMessage.text)?_c('div',{staticClass:"form__block--line form__messages mb-4",class:{
							'form__messages--error': _vm.errorMessage.status != 200,
							'form__messages--success': _vm.errorMessage.status == 200,
						}},[_c('label',{staticClass:"form__block--input"},[_c('span',{staticClass:"form__block--title"},[_vm._v(" "+_vm._s(_vm.errorMessage.text)+" ")])])]):_vm._e(),_c('div',{staticClass:"block__row"},[_c('a',{staticClass:"block__item block__item--blue",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<modal
		class="modal__block modal__block--middle modal__block--success"
		:name="name"
		:width="550"
		:min-width="550"
		height="auto"
	>
		<div class="modal__block--wrapper">
			<div class="modal__block--close" @click="close">
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
			</div>
			<div class="modal__wrapper">
				<div class="modal__header">
					<div class="modal__title" style="min-height: 20px">{{ title }}</div>
				</div>

				<div class="modal__content">
					<div class="modal__form">
						<div
							v-if="message.text"
							class="form__block--line form__messages"
							:class="{
								'form__messages--error': message.status != 200,
								'form__messages--success': message.status == 200,
							}"
						>
							<label class="form__block--input">
								<span class="form__block--title">
									{{ message.text }}
								</span>
							</label>
						</div>
						<div
							v-if="errorMessage.text"
							class="form__block--line form__messages mb-4"
							:class="{
								'form__messages--error': errorMessage.status != 200,
								'form__messages--success': errorMessage.status == 200,
							}"
						>
							<label class="form__block--input">
								<span class="form__block--title">
									{{ errorMessage.text }}
								</span>
							</label>
						</div>
						<div class="block__row">
							<a class="block__item block__item--blue" @click="close">
								{{ $t('button.close') }}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
export default {
	name: 'ReportManageErrorModal',
	props: {
		title: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: 'ReportManageErrorModal',
		},
		message: {
			type: Object,
			default: null,
		},
		errorMessage: {
			type: Object,
			default: null,
		},
	},
	emits: ['close'],
	methods: {
		close() {
			this.$modal.hide(this.name)
			this.$emit('close')
		},
	},
}
</script>
